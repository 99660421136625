



















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Enrollment from '@/store/entities/courses/enrollment';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import History from '@/views/_shared/history.vue';

class PageEnrollmentRequest extends PageRequest {
    course: number = null;
    state: number = null;
    date_from: string = new Date().toISOString().substr(0, 8) + '01';
    date_to: string = new Date().toISOString().substr(0, 10);
}

@Component({
    components: { ListView, ModalView, FormBase, History }

})
export default class Enrollments extends ListBase {
    pagerequest: PageEnrollmentRequest = new PageEnrollmentRequest();
    enrollment: Enrollment = new Enrollment();
    filterForm: boolean = true;
    hisModalShow: boolean = false;
    modalSetting: any = {
        entity: "enrollment",
        title: this.L('Matriculas'),
        width: "600px"
    };

    filterItems = [
        this.gItem({ type: 'object', prop: 'course_class', label: 'Curso - Clase', size: 6, hideDetails: true, onchange: this.changeCourse }),
        this.gItem({ type: 'object', prop: 'state', label: 'Estado', size: 2, generalType: 'E_ENROLL', hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Fechas Desde', size: 2, hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 2, hideDetails: true, onchange: this.afterSave }),
    ]

    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true, size: 8 }),
        this.gItem({ type: 'active', size: 4 }),
        this.gItem({ type: 'text', prop: 'symbol', label: 'Simbolo', required: true, size: 4 }),
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo Sunat', required: true, size: 4 }),
        this.gItem({ type: 'object', prop: 'unit_type_id', label: 'Tipo de Unidad', size: 4, generalType: 'T_UNITY' }),
    ]

    columns = [
        this.gCol('student.fullname', 'Asistente', 'text'),
        this.gCol('amount', 'Monto', 'price'),
        this.gCol('ranking', 'Calificacion', 'text'),
        this.gCol('created_at', 'Fecha de Matricula', 'date'),
        this.gCol('authorized', 'Autorizado', 'boolean'),
        this.gColO({ type: 'chip', value: 'state_id', text: 'Estado', color: 'stateColor', generalType: true, onclick: this.showHistory }),
        //this.defaultActions()
    ]

    changeCourse(id) {
        this.setItem(this.filterItems, 'schedule', 'filterVal', [id]);
        this.afterSave();
    }

    showHistory(item) {
        this.$store.commit('enrollment/view', item);
        this.hisModalShow = !this.hisModalShow;
    } 

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }

    async created() {
        //this.getpage();
    }
}