






























    import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
    import AbpBase from '@/lib/abpbase'
    import ModalView from '@/components/helper/modal-view.vue';
import util from '@/lib/util';

    @Component({
        components: { ModalView },
        watch: {
            value: function (val) {
                let obj = (this as any);
                obj.modalShow = val;
                if (val) {
                    obj.getpage();
                }
            },
            modalShow: function (val) {
                if (!val)
                    this.$emit('input', false);
            }
        }
    })
    export default class History extends AbpBase {
        @Prop({ type: Boolean, default: false }) value: boolean;
        @Prop({ type: String }) entity: string;
        @Prop({ type: String }) title: string;
        @Prop({ type: String, default: 'getHistory' }) action: string;
        modalShow: boolean = false;
        modalSetting: any = {
            entity: this.entity,
            titleFull: 'Historial de ' + this.title,
            width: '500px',
            icon: 'history',
            showForm: false,
            loadingProp: 'hisLoading',
            showFootActions: false,
            persistent: false
        };

        get list() {
            return this.$store.state[this.entity].history;
        }
        get loading() {
            return this.$store.state[this.entity].hisLoading;
        }

        state(id) {
            return util.getTypeValueById(id);    
        }

        async getpage() {
            await this.$store.dispatch({
                type: this.entity + '/' + this.action,
            });
        }

        created() {

        }

        mounted() {

        }
    }